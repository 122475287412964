@import '../_variables.scss';
.modal__container {
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 1000;
    
    .modal__box {
        background: white;
        width: 90%;
        height: 90%;
        border-radius: 10px;
        box-shadow: 0 0 10px black;
        display: flex;
        flex-direction: column;

        .modal__header {
            padding: 20px 25px 15px 25px;
            //border-bottom: 2px solid $color2-dark;
            font-size: 1.2em;
            font-weight: bold;
            color: $color1-lighter;
            display: flex;
            justify-content: space-between;

            .modal__close {
                cursor: pointer;
                margin-right: -8px;
                margin-top: -8px;
            }
        }

        .modal__content-container {
            flex-grow: 1;
            padding: 0 30px;
            margin-bottom: 30px;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
    
}

@media only screen and (min-width: 961px) {

    .modal__container {
        .modal__box {
            max-width: 961px;

            .modal__content-container {
                padding: 0 40px;
                margin-bottom: 40px;
            }
        }
    
    }
}

@media only screen and (min-width: 1200px) {
    .modal__box {
        max-width: 961px;
    }
}