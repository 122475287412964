.row {
    display: flex;
    flex-direction: row;
}

.row-reverse {
    display: flex;
    flex-direction: row-reverse;
}

.col-half {
    width: calc(50% - 10px);
    margin: 5px;
}

.col-half:first-child {
    width: calc(50% - 5px);
    margin-left: 0;
}

.col-half:last-child {
    width: calc(50% - 5px);
    margin-right: 0;
}