@import '_variables.scss';

table {
    max-width: 100%;
    margin-top: 50px;

    th {
        text-align: left;
        font-weight: bold;
    }

    th.desktop-only, td.desktop-only {
        display: none;

        @media only screen and (min-width: $screen-limit-1) {
            display: table-cell;
        }
    }

    td, th {
        margin: 0;
        padding: 10px 5px;
        //white-space: break;
        //word-break: break-word;
    }
}

.table-full-width {
    width: 100%;

    tr {
        vertical-align: middle;
    }

}

.table-last-td-right {
    td:last-child {
        text-align:right;
    }
}

.table-scroll-container {
    width: 100%;
    overflow-x: auto;

    table {
        width: auto;

        td {
            white-space: nowrap;
            word-break: none;
        }

        th {
            white-space: nowrap;
            word-break: none;
        }
    }

    .table-full-width {
        min-width: 100%;
    }
}

.table-hor-lines-between {

    tr {
        border-bottom: 1px solid $color2-dark;
    }

    tr:last-child {
        border-bottom: none;
    }

}