$pink: #FF00FF;

// Dimensions
$screen-limit-1: 961px;
$screen-limit-2: 1200px;

// Colors
$black: #000000;
$black-light: #333333;
$black-lighter: #4F4F4F;
$white: #FFFFFF;
$ocean-blue: #39667F;
$gray: #C4C4C4;
$gray-light: #F2F2F2;
$gray-dark: #A0A0A0;
$gray-darker: #5F5F5F;
$red: #C40000;
$red-dark: #8E0000;
$green: #2ec016;


// Palette
$color1: $black;
$color1-light: $black-light;
$color1-lighter: $black-lighter;
$color2: $gray;
$color2-dark: $gray-dark;
$color2-darker: $gray-darker;
$color2-light: $gray-light;
$color3: $ocean-blue;
$color4: $white;
$danger: $red;
$success: $green;