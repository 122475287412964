@import '_variables.scss';

body {
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
}

body, #root, .content-wrapper, .content {
    max-width: 100%;
}

.top-bar {
    background: $color1-light;
}

.hidden {
    display: none;
}

.content {
    padding: 30px;
    max-width: 100%;
}

p {
    margin-top: 10px;
}

i {
    font-style: italic;
}

h1 {
    font-size: 1.6em;
    color: $color1-lighter;
    margin-bottom: 25px;
    margin-top: 30px;

    strong {
        font-weight: bold;
        color: $color3;
    }
}

h1:first-child {
    margin-top: 0;
}

h2 {
    font-size: 1.4em;
    color: $color1-light;
    margin-bottom: 15px;
    margin-top: 30px;
}

p.light {
    font-style: italic;
    color: $color1-lighter;
}

input {
    display: block;
    font-size: 1.2em;
    margin-top: 5px;
    width: calc(100% - 10px);
    padding: 5px;

    border: none;
    border-bottom: 1px solid $color1-light;
    background: $color2-light;
}

input[type="radio"] {
    width: auto;
    display: inline;
}

input[type="checkbox"] {
    width: auto;
    display: block;
}

input[type="checkbox"].inline {
    display: inline;
    margin-left: 15px;
}

input[type="checkbox"].inline:first-child {
    margin-left: 0;
}

label {
    font-size: 1.1em;
    margin-top: 20px;
    display: block;
}

select {
    font-size: 1.1em;
    width: 100%;
    margin-top: 5px;
}

.btn {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    font-size: 1.2em;
    line-height: normal;
    border-radius: 5px;
    box-shadow: 0 5px 3px 0 $color2;
    text-align: center;
    cursor: pointer;
    background: $color3;
    color: $color4;
}

.btn:focus {
    transform: translateY(3px);
}

.btn-small {
    width: auto;
    font-size: 1em;
    padding: 7px 15px;
}

.btn-secondary {
    background: $color2-dark;
}

.btn-danger {
    background: $danger;
}

.btn-back {
    width: auto;
    background: none;
    box-shadow: none;
    color: $color2;
    text-decoration: none;
}

.btn-back:before {
    content: '< ';
}

.btn-link {
    padding: 0;
    display: inline;
    background: transparent;
    color: $color2-dark;
    text-decoration: underline;
    box-shadow: none;
}

@media only screen and (min-width: $screen-limit-1) {
    .content-wrapper {
        width: 961px;
        margin: 0 auto;
    }

    .btn {
        display: inline-block;
        width: auto;
        margin-right: 10px;
    }
}

@media only screen and (min-width: $screen-limit-2) {
    .content-wrapper {
        width: 1200px;
        margin: 0 auto;
    }
}