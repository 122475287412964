@import "_variables.scss";

.navbar {
  .navbar__top-wrapper {
    display: flex;
    justify-content: space-between;

    .navbar__logo {
      color: $color2-light;
      text-decoration: none;
      padding: 15px;
      display: inline-block;
    }

    .navbar__hamburger-menu {
      flex-grow: 0;
      margin-right: 20px;
      font-size: 1.5em;
      color: $color4;
      cursor: pointer;
    }
  }

  .navbar__drawer {
    max-height: 0;
    overflow: hidden;
    background: $color1-lighter;

    transition: max-height 0.5s ease-in-out;

    .navbar__element {
      display: block;
      width: 100%;
      white-space: nowrap;
    }

    .navbar__link {
      color: $color4;
      text-decoration: none;
      display: inline-block;
      padding: 15px;
      background: none;
      cursor: pointer;
      width: 100%;
      text-align: left;
    }

    .navbar__link:hover,
    .navbar__link:active {
      background: rgba(255, 255, 255, 0.05);
    }
  }

  .navbar__drawer--active {
    max-height: 800px;
  }

  .navbar__subdrawer {
    max-height: 0px;
    height: auto;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;

    background: $color2-darker;

    .navbar__element {
      padding-left: 30px;
    }
  }

  .navbar__subdrawer-container {
    display: flex;
    flex-direction: column;

    .navbar__element {
      flex-grow: 1;
    }
  }

  .navbar__subdrawer--active {
    max-height: 380px;
  }

  .navbar__subdrawer-link {
    position: relative;
    i {
      display: inline-block;
      font-size: 1.3em;
      position: absolute;
      right: 20px;
    }
  }
}

@media only screen and (min-width: 961px) {
  .navbar {
    display: flex;
    max-width: 961px;
    margin: 0 auto;

    .navbar__hamburger-menu {
      display: none;
    }

    .navbar__drawer {
      max-height: 500px;
      background: transparent;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .navbar__element,
      .navbar__link {
        width: auto;
        line-height: 1em;
      }
    }

    .navbar__subdrawer {
      max-height: 0;
    }

    .navbar__subdrawer--active {
      max-height: 400px;
    }

    .navbar__subdrawer-container {
      flex-direction: row;
    }

    .navbar__subdrawer {
      display: flex;
      flex-direction: column;
      position: fixed;
      top: calc(1em + 31px);

      box-shadow: 0 3px 5px 0 $color1-lighter;

      transition: max-height 0.3s ease-in-out;

      .navbar__element {
        padding-left: 0;
      }

      .navbar__link {
        width: 100%;
      }
    }

    .navbar__subdrawer-link {
      position: static;
      line-height: 1em;
      i {
        font-size: 1em;
        width: 0.4em;
        position: static;
        margin-left: 10px;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .navbar {
    max-width: 1200px;
  }
}
